import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_CENTRAL_API_URL;
//TODO:centerilize token
function getToken() {
  let token = localStorage.getItem("token");
  if (token) {
    return JSON.parse(token);
  }
}

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    // const token = getState().auth.token
    const token = getToken();

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions = {}) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401 && window.location.pathname !== "/login") {
    // 401
    window.location.href = "/login";
    //api.dispatch("whatever action you need");
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "videos",
    "userProfil",
    "user",
    "course",
    "trail",
    "school",
    "lecture",
    "attendance",
    "teacher",
    "courseTeacher",
    "presentation",
    "guidance",
    "students-in-class",
    "activationKey",
    "smsCodeStatus",
    "competition",
    "banner",
  ],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    paths: builder.query({
      query: (data) => ({
        url: "/learningpaths",
        method: "get",
      }),
    }),
    practice: builder.query({
      query: (data) => ({
        url: `/learningpaths/${data.id}/practices/`,
        method: "get",
      }),
    }),
    getAllPractices: builder.query({
      query: () => ({
        url: `/practices/`,
        method: "get",
      }),
    }),
    getSinglePractice: builder.query({
      query: (id) => ({
        url: `/practices/${id}`,
        method: "get",
      }),
    }),
    lesson: builder.query({
      query: ({ id }) => ({
        url: `courses/lessons/${id}/`,
        method: "get",
      }),
    }),
    courses: builder.query({
      query: (school_status, schoolId) => ({
        url: `/courses/?${school_status}&${schoolId}`,
        method: "get",
      }),
      providesTags: ["course"],
    }),
    singleCourse: builder.query({
      query: (id) => ({
        url: `/courses/${id}`,
        method: "get",
      }),
      providesTags: ["course"],
    }),
    createCourse: builder.mutation({
      query: ({
        title,
        technology,
        description,
        order_number,
        status,
        level,
        school,
        school_status,
        chapters,
      }) => ({
        url: `courses/`,
        method: "post",
        body: {
          title,
          technology,
          description,
          order_number,
          status,
          level,
          school,
          school_status,
          chapters,
        },
      }),
      invalidatesTags: ["course"],
    }),
    updateCourse: builder.mutation({
      query: ({
        id,
        title,
        technology,
        description,
        order_number,
        status,
        level,
        chapters,
        points,
        labels,
        school,
        school_status,
      }) => ({
        url: `courses/${id}/`,
        method: "put",
        body: {
          id,
          title,
          technology,
          description,
          order_number,
          status,
          level,
          chapters,
          points,
          labels,
          school,
          school_status,
        },
      }),
      invalidatesTags: ["course"],
    }),
    deleteCourse: builder.mutation({
      query: (id) => ({
        url: `courses/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["course"],
    }),
    games: builder.query({
      query: (data) => ({
        url: "/games",
        method: "get",
      }),
    }),
    gamesWithSection: builder.query({
      query: (data) => ({
        url: `/games/${data.id}/sections`,
        method: "get",
      }),
    }),
    gameStepsWithSection: builder.query({
      query: (data) => ({
        url: `/games/${data.id}/sections/${data.sectionId}/steps`,
        method: "get",
      }),
    }),
    gameStepWithSectionId: builder.query({
      query: (data) => ({
        url: `/games/${data.gameId}/sections/${data.sectionId}/steps/${data.stepId}`,
        method: "get",
      }),
    }),
    gameStepsWithoutSection: builder.query({
      query: (data) => ({
        url: `/games/${data.id}/sections/steps`,
        method: "get",
      }),
    }),
    gameStepWithoutSectionId: builder.query({
      query: (data) => ({
        url: `/games/${data.gameId}/sections/steps/${data.stepId}`,
        method: "get",
      }),
    }),
    leaderBoards: builder.query({
      query: (data) => ({
        url: "leaderboards/totalpoints/",
        method: "get",
      }),
    }),
    leaderBoardsInClass: builder.query({
      query: (id) => ({
        url: `leaderboards/class/${id}/`,
        method: "get",
      }),
    }),

    user: builder.query({
      query: (data) => ({
        url: `users/${data.userId}`,
        method: "get",
      }),
      providesTags: ["userProfil"],
    }),
    updateProfil: builder.mutation({
      query: ({
        id,
        first_name,
        last_name,
        username,
        user_avatar,
        school,
        birth_date,
        parent_telephone_number,
      }) => ({
        url: `auth/update_profile/${id}/`,
        method: "put",
        body: {
          id,
          first_name,
          last_name,
          username,
          user_avatar,
          school,
          birth_date,
          parent_telephone_number,
        },
      }),
      invalidatesTags: ["userProfil"],
    }),
    activeDays: builder.query({
      query: (data) => ({
        url: "useractivies/activedays/month/",
        method: "get",
      }),
    }),
    activeDaysDate: builder.query({
      query: (data) => ({
        url: "useractivies/activedays/",
        method: "get",
      }),
    }),
    followingLessons: builder.query({
      query: () => ({
        url: "useractivies/lessons_followed/",
        method: "get",
      }),
    }),
    userActivitiesInLastDays: builder.query({
      query: () => ({
        url: "useractivies/in_last_days/",
        method: "get",
      }),
    }),
    dailyAdvice: builder.query({
      query: (data) => ({
        url: "daily_advice/",
        method: "get",
      }),
    }),
    addDailyAdvice: builder.mutation({
      query: (title) => ({
        url: "daily_advice/",
        method: "post",
        body: { title },
      }),
    }),
    allUserLessonActivities: builder.query({
      query: () => ({
        url: "useractivies/lessons/",
        method: "get",
      }),
      providesTags: ["videos"],
    }),
    addUserLessonActivities: builder.mutation({
      query: (data) => ({
        url: "useractivies/lessons/",
        method: "post",
        body: { lesson: data.lesson, points: data.points, competition: data.competition },
      }),
      invalidatesTags: ["videos"],
    }),
    removeUserLessonActivite: builder.mutation({
      query: (lesson) => ({
        url: `useractivies/lessons/${lesson}`,
        method: "delete",
        body: { lesson },
      }),
      invalidatesTags: ["videos"],
    }),
    userSertificates: builder.query({
      query: () => ({
        url: "awards/certificates/",
        method: "get",
      }),
    }),
    userBadges: builder.query({
      query: () => ({
        url: "awards/badges/",
        method: "get",
      }),
    }),
    userBadgesStudent: builder.query({
      query: () => ({
        url: "awards/badges/student",
        method: "get",
      }),
    }),
    clearCache: builder.mutation({
      query: () => ({
        url: "system_dashboard/cahce/flush/",
        method: "delete",
      }),
    }),
    usersSearch: builder.query({
      query: (search, page_size, page) => ({
        url: `users/?${search}&${page}&${page_size}`,
        method: "get",
      }),
      providesTags: ["user", "students-in-class"],
    }),
    users: builder.query({
      query: (school, role, page, page_size) => ({
        url: `users/?${school}&${role}&${page}&${page_size}`,
        method: "get",
      }),
      providesTags: ["user", "students-in-class"],
    }),
    getUser: builder.query({
      query: (id) => ({
        url: `users/${id}/`,
        method: "get",
      }),
    }),
    createUser: builder.mutation({
      query: ({
        first_name,
        last_name,
        email,
        subscription_type,
        subscription_period,
        role,
        telNumber,
        parent_telephone_number,
        school,
      }) => ({
        url: `users/`,
        method: "post",
        body: {
          first_name,
          last_name,
          email,
          subscription_type,
          subscription_period,
          role,
          telNumber,
          parent_telephone_number,
          school,
        },
      }),
      invalidatesTags: ["user"],
    }),
    updateUser: builder.mutation({
      query: ({
        id,
        first_name,
        last_name,
        email,
        subscription_type,
        subscription_period,
        role,
        telNumber,
        parent_telephone_number,
        school,
      }) => ({
        url: `users/${id}/`,
        method: "put",
        body: {
          id,
          first_name,
          last_name,
          email,
          subscription_type,
          subscription_period,
          role,
          telNumber,
          parent_telephone_number,
          school,
        },
      }),
      invalidatesTags: ["user"],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `users/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["user"],
    }),
    getUserSubscriptionType: builder.query({
      query: () => ({
        url: `users/subscription_type/`,
        method: "get",
      }),
    }),
    getTotalStudent: builder.query({
      query: () => ({
        url: `classes/total/`,
        method: "get",
      }),
    }),
    joinToClass: builder.mutation({
      query: (class_code) => ({
        url: `classes/student/join/`,
        method: "post",
        body: { class_code },
      }),
    }),
    getSchoolStudentsWithoutClass: builder.query({
      query: ({schoolId, page, page_size}) => ({
        url: `schools/${schoolId}/students/unassigned/?${page}&${page_size}`,
        method: "get",
      }),
      providesTags: ["students-in-class"],
    }),
    getStudentSubscribedClasses: builder.query({
      query: () => ({
        url: `classes/students/`,
        method: "get",
      }),
    }),
    getListStudentsHomework: builder.query({
      query: () => ({
        url: `classes/students/homeworks/`,
        method: "get",
      }),
    }),
    getStudentHomework: builder.query({
      query: (id) => ({
        url: `classes/students/homeworks/${id}/`,
        method: "get",
      }),
    }),
    trails: builder.query({
      query: (school_status) => ({
        url: `/trails/?${school_status}`,
        method: "get",
      }),
      providesTags: ["trail"],
    }),
    singleTrail: builder.query({
      query: (data) => ({
        url: `/trails/${data.id}`,
        method: "get",
      }),
      providesTags: ["trail"],
    }),
    createTrail: builder.mutation({
      query: ({
        title,
        description,
        order_number,
        status,
        courses,
        school_status,
        school,
      }) => ({
        url: `trails/`,
        method: "post",
        body: {
          title,
          description,
          order_number,
          status,
          courses,
          school_status,
          school,
        },
      }),
      invalidatesTags: ["trail"],
    }),
    updateTrail: builder.mutation({
      query: ({
        id,
        title,
        description,
        order_number,
        status,
        courses,
        school_status,
        school,
      }) => ({
        url: `trails/${id}/`,
        method: "put",
        body: {
          id,
          title,
          description,
          order_number,
          status,
          courses,
          school_status,
          school,
        },
      }),
      invalidatesTags: ["trail"],
    }),
    deleteTrail: builder.mutation({
      query: (id) => ({
        url: `trails/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["trail"],
    }),
    updatePasswordWithAdmin: builder.mutation({
      query: ({ id, password, password2 }) => ({
        url: `users/password/${id}/`,
        method: "put",
        body: { id, password, password2 },
      }),
    }),
    teacherRegister: builder.mutation({
      query: ({ data }) => ({
        url: `auth/register/teacher/`,
        method: "post",
        body: data,
      }),
    }),
    adminDashboard: builder.query({
      query: () => ({
        url: `system_dashboard/admin/`,
        method: "get",
      }),
      providesTags: ["teacher"],
    }),
    approveTeacher: builder.mutation({
      query: ({ teacherId, isApproved }) => ({
        url: `users/teachers/${teacherId}/approvement/`,
        method: "put",
        body: { role_status: isApproved },
      }),
      invalidatesTags: ["teacher"],
    }),
    parentReport: builder.query({
      query: (publicId) => ({
        url: `users/information/${publicId}/`,
        method: "get",
      }),
    }),
    teacherReportForAdmin: builder.query({
      query: (data) => ({
        url: `users/teachers/${data.teacherId}/classes/${data.classId}/information/`,
        method: "get",
      }),
    }),
    listAllClassOfTeacher: builder.query({
      query: (teacherId) => ({
        url: `users/teachers/${teacherId}/classes/`,
        method: "get",
      }),
    }),
    searchActivationKeysOfSchoolForAdmin: builder.query({
      query: ({schoolId, page, page_size, search}) => ({
        url: `schools/${schoolId}/activation_key/?${search}&${page}&${page_size}`,
        method: "get",
      }),
      providesTags: ["activationKey"],
    }),
    getActivationKeysOfSchoolForAdmin: builder.query({
      query: ({schoolId, page, page_size, status, created_date}) => ({
        url: `schools/${schoolId}/activation_key/?${page}&${page_size}&${status}&${created_date}`,
        method: "get",
      }),
      providesTags: ["activationKey"],
    }),
    createActivationKeysOfSchool: builder.mutation({
      query: ({
        schoolId,
        subscription_type,
        subscription_period,
        code_expiry_date,
        number_of_the_codes,
      }) => ({
        url: `schools/${schoolId}/activation_key/`,
        method: "post",
        body: {
          schoolId,
          subscription_type,
          subscription_period,
          code_expiry_date,
          number_of_the_codes,
        },
      }),
      invalidatesTags: ["activationKey"],
    }),
    deleteActivationKeysOfSchool: builder.mutation({
      query: ({ schoolId, activation_key_ids }) => ({
        url: `schools/${schoolId}/activation_key/bulk`,
        method: "delete",
        body: { activation_key_ids },
      }),
      invalidatesTags: ["activationKey"],
    }),
    getActivationKeyCreateDatesOfSchoolForAdmin: builder.query({
      query: (schoolId) => ({
        url: `schools/${schoolId}/activation_key/created_dates/`,
        method: "get",
      }),
      providesTags: ["activationKey"],
    }),
    getAllActivationCodesOfSchool_ByTXTForAdmin: builder.query({
      query: (schoolId, created_date) => ({
        url: `schools/${schoolId}/activation_key/txt/?${created_date}`,
        method: "get",
      }),
      providesTags: ["activationKey"],
    }),
    getActivationKeyMessageOfSchoolForAdmin: builder.query({
      query: (schoolId) => ({
        url: `schools/${schoolId}/activation_key_message/`,
        method: "get",
      }),
      providesTags: ["activationKey"],
    }),
    updateActivationKeyMessageOfSchool: builder.mutation({
      query: ({ schoolId, message }) => ({
        url: `schools/${schoolId}/activation_key_message/`,
        method: "put",
        body: { message },
      }),
      invalidatesTags: ["activationKey"],
    }),
    deleteActivationKeyMessageOfSchool: builder.mutation({
      query: (schoolId) => ({
        url: `schools/${schoolId}/activation_key_message/`,
        method: "delete",
      }),
      invalidatesTags: ["activationKey"],
    }),

    enterActivationCodeByStudent: builder.mutation({
      query: ({ code }) => ({
        url: `schools/activation_key/student/`,
        method: "post",
        body: { code },
      }),
      invalidatesTags: ["activationKey"],
    }),
    getActivationCodeForStudent: builder.query({
      query: () => ({
        url: `schools/activation_key/student/`,
        method: "get",
      }),
      providesTags: ["activationKey"],
    }),

    getParentInformation: builder.query({
      query: (id) => ({
        url: `auth/information_system/student/${id}/`,
        method: "get",
      }),
      providesTags: ["smsCodeStatus"],
    }),
    updateParentInformation: builder.mutation({
      query: ({ id, parent_information_system }) => ({
        url: `auth/information_system/student/${id}/`,
        method: "put",
        body: { parent_information_system },
      }),
      invalidatesTags: ["smsCodeStatus"],
    }),
    createVerificationCodeForParent: builder.mutation({
      query: (parent_telephone_number) => ({
        url: `auth/verification_code/`,
        method: "post",
        body: { parent_telephone_number },
      }),
      invalidatesTags: ["smsCodeStatus"],
    }),
    confirmVerificationCodeForParent: builder.mutation({
      query: ({ id, verification_code }) => ({
        url: `auth/verification_code/student/${id}/`,
        method: "put",
        body: { verification_code },
      }),
      invalidatesTags: ["smsCodeStatus"],
    }),

    // Announcement  //
    getAllSchoolAnnouncementForAdmin: builder.query({
      query: (schoolId) => ({
        url: `schools/${schoolId}/annocucements/`,
        method: "get",
      }),
      providesTags: ["announcement"],
    }),
    getSchoolAnnouncementForAdmin: builder.query({
      query: ({ schoolId, id }) => ({
        url: `schools/${schoolId}/annocucements/${id}`,
        method: "get",
      }),
      providesTags: ["announcement"],
    }),
    createSchoolAnnouncementForAdmin: builder.mutation({
      query: ({ formData, schoolId }) => ({
        url: `schools/${schoolId}/annocucements/`,
        method: "post",
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
        body: formData,
      }),
      invalidatesTags: ["announcement"],
    }),
    updateSchoolAnnouncementForAdmin: builder.mutation({
      query: ({ formData, id, schoolId }) => ({
        url: `schools/${schoolId}/annocucements/${id}/`,
        method: "put",
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
        body: formData,
      }),
      invalidatesTags: ["announcement"],
    }),
    deleteSchoolAnnouncementForAdmin: builder.mutation({
      query: ({ id, schoolId }) => ({
        url: `schools/${schoolId}/annocucements/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["announcement"],
    }),

    // banner section
    getSchoolBannerForAdmin: builder.query({
      query: (schoolId) => ({
        url: `schools/${schoolId}/banner/`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'))}`,
        },
        responseHandler: async (response) => {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        },
        method: "get",
      }),
      providesTags: ["banner"],
    }),
    createSchoolBannerForAdmin: builder.mutation({
      query: ({data, schoolId}) => ({
        url: `schools/${schoolId}/banner/`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["banner"],
    }),
    updateSchoolBannerForAdmin: builder.mutation({
      query: ({data, schoolId}) => ({
        url: `schools/${schoolId}/banner/`,
        method: "put",
        body: data,
      }),
      invalidatesTags: ["banner"],
    }),
    deleteSchoolBannerForAdmin: builder.mutation({
      query: ({data, schoolId}) => ({
        url: `schools/${schoolId}/banner/`,
        method: "delete",
        body: data,
      }),
      invalidatesTags: ["banner"],
    }),
  }),
});

export const {
  usePathsQuery,
  usePracticeQuery,
  useGetAllPracticesQuery,
  useGetSinglePracticeQuery,
  useCoursesQuery,
  useSingleCourseQuery,
  useCreateCourseMutation,
  useUpdateCourseMutation,
  useDeleteCourseMutation,

  useGamesQuery,
  useGamesWithSectionQuery,
  useGameStepsWithSectionQuery,
  useGameStepWithSectionIdQuery,
  useGameStepsWithoutSectionQuery,
  useGameStepWithoutSectionIdQuery,

  useLeaderBoardsQuery,
  useLeaderBoardsInClassQuery,
  useActiveDaysQuery,
  useActiveDaysDateQuery,
  useFollowingLessonsQuery,
  useUserActivitiesInLastDaysQuery,
  useDailyAdviceQuery,
  useAddDailyAdviceMutation,
  useUsersSearchQuery,
  useUserQuery,
  useUpdateProfilMutation,
  useAddUserLessonActivitiesMutation,
  useAllUserLessonActivitiesQuery,
  useRemoveUserLessonActiviteMutation,
  useUserSertificatesQuery,
  useUserBadgesQuery,
  useUserBadgesStudentQuery,
  useUsersQuery,
  useClearCacheMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useCreateUserMutation,
  useGetUserSubscriptionTypeQuery,
  useGetTotalStudentQuery,
  useJoinToClassMutation,
  useGetStudentSubscribedClassesQuery,
  useGetSchoolStudentsWithoutClassQuery,
  useGetListStudentsHomeworkQuery,
  useGetStudentHomeworkQuery,
  useTrailsQuery,
  useSingleTrailQuery,
  useCreateTrailMutation,
  useUpdateTrailMutation,
  useDeleteTrailMutation,
  useLessonQuery,
  useUpdatePasswordWithAdminMutation,
  useTeacherRegisterMutation,
  useAdminDashboardQuery,
  useApproveTeacherMutation,
  useParentReportQuery,
  useTeacherReportForAdminQuery,
  useListAllClassOfTeacherQuery,

  useSearchActivationKeysOfSchoolForAdminQuery,
  useGetActivationKeysOfSchoolForAdminQuery,
  useCreateActivationKeysOfSchoolMutation,
  useDeleteActivationKeysOfSchoolMutation,
  useGetActivationKeyCreateDatesOfSchoolForAdminQuery,
  useGetAllActivationCodesOfSchool_ByTXTForAdminQuery,
  useGetActivationKeyMessageOfSchoolForAdminQuery,
  useUpdateActivationKeyMessageOfSchoolMutation,
  useDeleteActivationKeyMessageOfSchoolMutation,

  useEnterActivationCodeByStudentMutation,
  useGetActivationCodeForStudentQuery,

  useGetParentInformationQuery,
  useUpdateParentInformationMutation,
  useCreateVerificationCodeForParentMutation,
  useConfirmVerificationCodeForParentMutation,

  useGetAllSchoolAnnouncementForAdminQuery,
  useGetSchoolAnnouncementForAdminQuery,
  useCreateSchoolAnnouncementForAdminMutation,
  useUpdateSchoolAnnouncementForAdminMutation,
  useDeleteSchoolAnnouncementForAdminMutation,

  useGetSchoolBannerForAdminQuery,
  useCreateSchoolBannerForAdminMutation,
  useUpdateSchoolBannerForAdminMutation,
  useDeleteSchoolBannerForAdminMutation,
} = apiSlice;
