const USER_PAYMENT_PLAN_TYPE = {
    STANDARTMOUNTHLY : {
        id: 1,
        newPrice: '200',
        oldPrice: '300',
    },
    STANDARTYEARLY : {
        id: 2,
        newPrice: '2000',
        oldPrice: '2400',
    },
    PREMIUMMOUNTHLY : {
        id: 3,
        newPrice: '350',
        oldPrice: '450',
    },
    PREMIUMYEARLY : {
        id: 4,
        newPrice: '3500',
        oldPrice: '4200',
    },
};
export default USER_PAYMENT_PLAN_TYPE;