import React from 'react';

const Privacy = ({isNewUser,classNames}) => {
    return (
             <div className="text-center">
              <a
                href="/"
                className={classNames("text-decoration-none me-3 text-light")}
              >
                Gizlilik bildirimi
              </a>
              <a
                href="/"
                className={classNames("link-light text-decoration-none text-light")}
              >
                Kullanım Şartları
              </a>
            </div>
    );
};

export default Privacy;